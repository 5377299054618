
import { Component } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ReservationModule from "@/store/reservation";
import AuthModule from "@/store/auth";
import HospitalModule from "@/store/hospital";
import moment from "moment";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import { UpdateUser } from "@/@types/register";
import MemberService from "@/services/members";
import {
  AddPet,
  KeeperAnimalData,
  NewAnimalInfo,
  NewReservation,
  NewReservationsData,
  PetData,
  PetForm,
  ReservationData,
} from "@/@types/reservation";
import { deleteNullProp, asyncForEach, parsePhoneNumber, authCheck } from "@/utils/Util";
import Cognito from "@/plugins/cognito";
@Component({
  name: "ReservationConfirm",
  components: {
    Header,
    Footer,
  },
})
export default class ReservationConfirm extends CheckCommonLogin {
  private get reservationInfo() {
    return ReservationModule.reservationInfo;
  }
  private get selectedDate() {
    return ReservationModule.selectedDate;
  }
  private get selectedCourse() {
    return ReservationModule.selectedCourse;
  }
  private get selectedMyPets() {
    return ReservationModule.selectedMyPets;
  }
  private get keeper() {
    return ReservationModule.keeper;
  }
  private get hospitalId() {
    return HospitalModule.hospitalId;
  }
  private get hospitalName() {
    return HospitalModule.hospitalName;
  }

  private get me() {
    return AuthModule.me;
  }

  private toCourseSelect() {
    this.$router.push({ name: "CourseSelect", query: this.$route.query });
  }
  private toReservationForm() {
    this.$router.push({ name: "HospitalReservation", query: this.$route.query });
  }
  private loading = false;

  private reservationError = false;

  private get startTime() {
    const weekdaysShort = ["日", "月", "火", "水", "木", "金", "土"];

    const formatDate = moment(this.selectedDate);
    const m = formatDate.month() + 1;
    const d = formatDate.date();
    const day = formatDate.day();
    const h = formatDate.format("HH");
    const minute = formatDate.format("mm");
    return `${m}/${d} (${weekdaysShort[day]}) ${h}:${minute} 〜`;
  }

  private back() {
    window.scrollTo(0, 0);
    this.$router.push({ name: "HospitalReservation", query: this.$route.query });
  }

  private newMe: UpdateUser = {};
  private newReservation: NewReservation = {
    facility_id: "",
    pet_ids: [],
    customer_id: "",
    patient_ids: [],
    datetime: "",
    course_id: "",
    message: "",
    memo: "",
  };

  private async newReservationFactory(memberId: string) {
    this.newReservation.facility_id = this.hospitalId;
    this.newReservation.datetime = moment(this.selectedDate).format();
    if (this.selectedCourse && this.selectedCourse.id) this.newReservation.course_id = this.selectedCourse.id;
    this.newReservation.message = this.reservationInfo.symptoms;
    this.newReservation.memo = this.reservationInfo.memo;
    if (this.reservationInfo.pets.length) {
      await asyncForEach(this.reservationInfo.pets, async (pet: PetForm, index: number) => {
        const newPet: AddPet = {
          name: "",
          name_kana: "",
          gender: "",
          animal_type: "",
          animal_breed: "",
          insurance: "",
          birthday: "",
        };
        newPet.name = pet.petName;
        newPet.name_kana = pet.kanaPetName;
        if (pet.petSex) newPet.gender = pet.petSex;
        newPet.animal_type = pet.petType;
        newPet.animal_breed = pet.petTypeDescription;
        let birthday = "";
        if (pet.petBirthDay.year) {
          birthday = `${pet.petBirthDay.year}`;
        } else {
          birthday += "0000";
        }
        if (pet.petBirthDay.month) {
          birthday += `${pet.petBirthDay.month}`;
        } else {
          birthday += "00";
        }
        if (pet.petBirthDay.day) {
          birthday += `${pet.petBirthDay.day}`;
        } else {
          birthday += "00";
        }
        newPet.birthday = birthday == "00000000" ? "" : birthday;
        try {
          const payload = deleteNullProp(newPet);
          const res = await MemberService.createPet(memberId, payload);
          const petId = res.data.data[0].id;
          this.reservationInfo.pets[index].id = petId;
          if (!this.newReservation.pet_ids?.includes(petId)) this.newReservation.pet_ids?.push(petId);
        } catch (error: any) {
          throw new Error(error);
        }
      });
    }

    this.selectedMyPets.forEach((pet: KeeperAnimalData) => {
      if (!this.newReservation.pet_ids?.includes(pet.animalId)) this.newReservation.pet_ids?.push(pet.animalId);
    });
  }

  private checkMeEdited() {
    if (this.me) {
      if (this.reservationInfo.lastName !== this.me.last_name) {
        this.newMe.last_name = this.reservationInfo.lastName;
      }
      if (this.reservationInfo.kanaLastName !== this.me.last_name_kana) {
        this.newMe.last_name_kana = this.reservationInfo.kanaLastName;
      }
      if (this.reservationInfo.firstName !== this.me.first_name) {
        this.newMe.first_name = this.reservationInfo.firstName;
      }
      if (this.reservationInfo.kanaFirstName !== this.me.first_name_kana) {
        this.newMe.first_name_kana = this.reservationInfo.kanaFirstName;
      }
      if (this.reservationInfo.email !== this.me.email) {
        this.newMe.email = this.reservationInfo.email;
      }
      if (
        this.reservationInfo.phoneNumber.number1 &&
        this.reservationInfo.phoneNumber.number2 &&
        this.reservationInfo.phoneNumber.number3
      ) {
        if (
          parsePhoneNumber(
            this.reservationInfo.phoneNumber.number1 +
              this.reservationInfo.phoneNumber.number2 +
              this.reservationInfo.phoneNumber.number3
          ) !== this.me.tel
        ) {
          this.newMe.tel = parsePhoneNumber(
            this.reservationInfo.phoneNumber.number1 +
              this.reservationInfo.phoneNumber.number2 +
              this.reservationInfo.phoneNumber.number3
          );
        }
      } else {
        this.newMe.tel = "";
      }
      if (
        this.reservationInfo.postalCode.number1 &&
        this.reservationInfo.postalCode.number2 &&
        `${this.reservationInfo.postalCode.number1}-${this.reservationInfo.postalCode.number2}` !== this.me.zip_code
      ) {
        this.newMe.zip_code = `${this.reservationInfo.postalCode.number1}-${this.reservationInfo.postalCode.number2}`;
      }
      if (this.reservationInfo.address !== this.me.address) {
        this.newMe.address = this.reservationInfo.address;
      }
    }
  }

  private isEmpty(obj: any) {
    return !Object.keys(obj).length;
  }

  private async reservation() {
    this.loading = true;
    // this.checkMeEdited();
    if (this.me) {
      // if (!this.isEmpty(this.newMe)) {
      //   MemberService.update(this.me.id, this.newMe);
      // }
      try {
        const isMember = await authCheck(this.me.id);
        if (!isMember) {
          Cognito.signOut();
          localStorage.removeItem("vuex");
          this.$router.push({ name: "Login" });
          return;
        }
        await this.newReservationFactory(this.me.id);
        const year = String(moment(this.newReservation.datetime).get("year"));
        const month = String(moment(this.newReservation.datetime).get("month") + 1);
        const res = await MemberService.getPossibleReservations(
          this.hospitalId,
          this.newReservation.course_id,
          year,
          month
        );
        const possibleReservationCheck = res.data.data.find(
          (reservation: ReservationData) => reservation.datetime == this.newReservation.datetime
        );
        if (possibleReservationCheck && possibleReservationCheck.remained_count) {
          const payload: NewReservationsData[] = [];
          const reservationData: NewReservationsData = {
            facility_id: this.newReservation.facility_id,
            pet_id: "",
            customer_id: this.newReservation.customer_id,
            patient_id: "",
            datetime: this.newReservation.datetime,
            course_id: this.newReservation.course_id,
            message: this.newReservation.message,
            memo: this.newReservation.memo,
            keeper_info: this.keeper
              ? { keeper_id: this.keeper.keeperId }
              : {
                  last_name: this.reservationInfo.lastName,
                  first_name: this.reservationInfo.firstName,
                  email: this.reservationInfo.email,
                  last_name_kana: this.reservationInfo.kanaLastName,
                  first_name_kana: this.reservationInfo.kanaFirstName,
                  tel:
                    this.reservationInfo.phoneNumber.number1 &&
                    this.reservationInfo.phoneNumber.number2 &&
                    this.reservationInfo.phoneNumber.number3
                      ? this.reservationInfo.phoneNumber.number1 +
                        this.reservationInfo.phoneNumber.number2 +
                        this.reservationInfo.phoneNumber.number3
                      : "",
                  zip_code:
                    this.reservationInfo.postalCode.number1 && this.reservationInfo.postalCode.number2
                      ? this.reservationInfo.postalCode.number1 + "-" + this.reservationInfo.postalCode.number2
                      : "",
                  address: this.reservationInfo.address,
                },
          };
          reservationData.keeper_info = deleteNullProp(reservationData.keeper_info, true);
          // アニレセ対応
          this.reservationInfo.pets?.forEach((value) => {
            let birthday = "";
            if (value.petBirthDay.year) {
              birthday = `${value.petBirthDay.year}`;
            } else {
              birthday += "0000";
            }
            if (value.petBirthDay.month) {
              birthday += `${value.petBirthDay.month}`;
            } else {
              birthday += "00";
            }
            if (value.petBirthDay.day) {
              birthday += `${value.petBirthDay.day}`;
            } else {
              birthday += "00";
            }
            birthday = birthday == "00000000" ? "" : birthday;
            const newPet: NewAnimalInfo = {
              name: value.petName,
              animal_type: value.petType,
              animal_breed: value.petTypeDescription,
              name_kana: value.kanaPetName,
              gender: value.petSex,
              birthday,
            };
            const newPetPayload = deleteNullProp(newPet, true);
            const formattedReservationData = deleteNullProp(
              { ...reservationData, animal_info: newPetPayload, pet_id: value.id },
              true
            );
            payload.push(formattedReservationData);
          });
          if (this.selectedMyPets.length > 0) {
            this.selectedMyPets.forEach((value: KeeperAnimalData) => {
              const formattedReservationData = deleteNullProp(
                { ...reservationData, animal_info: { animal_id: value.animalId } },
                true
              );
              payload.push(formattedReservationData);
            });
          }
          // アニコムのみ
          // this.newReservation.pet_ids?.forEach((value) => {
          //   const formattedReservationData = deleteNullProp({ ...reservationData, pet_id: value }, true);
          //   payload.push(formattedReservationData);
          // });
          // this.newReservation.patient_ids?.forEach((value) => {
          //   reservationData.patient_id = value;
          //   const formattedReservationData = deleteNullProp({ ...reservationData, pet_id: value }, true);
          //   payload.push(formattedReservationData);
          // });
          await MemberService.createReservation(this.me.id, payload);
          this.$router.push({ name: "ReservationSuccess" });
        } else {
          this.reservationError = true;
          this.loading = false;
        }
      } catch (error: any) {
        this.loading = false;

        throw new Error(error);
      }
    }
  }
}
